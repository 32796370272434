import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Button, Drawer, Divider} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import * as appActions from 'app/store/actions';
import {Table, Typography, Input, Upload, Form, Modal, Spin, Row, Col, Icon, message, Tooltip} from 'antd';
import {beautifyJSON} from 'app/main/config';

const { Title } = Typography;
const { confirm } = Modal;
const FormItem = Form.Item;
const { Dragger } = Upload;
const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};
const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}
const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class DoorHandle extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            name      : '',
            gltf      : [],
            thumbnail : [],
            visible   : false,
            type      : "new",
            id        : null,
        };
        props.getDoorHandle();
       
    }

    multiUploadFileArray = (dataArray, record) => {
        const results = dataArray.map((obj,index) => ({
            uid     : index,
            name    : obj.split('/').pop(),
            status  : 'done',
            url     : obj,
        }));

        return results;
    }
    toggleDrawer = (type, record, open) => () => {
        if(type === "new") {
            this.setState({
                id        : null,
                name      : '',
                gltf      : [],
                thumbnail : [],
                type      : type,
            })
        }
        else if(type === "edit") {
            const gltfArray = JSON.parse(record.gltf);
            const gltfFileList = this.multiUploadFileArray(gltfArray.gltf, record);
            this.setState({
                name: record.name,
                type            : type,
                id              : record.id,
                guid            : record.guid,
                thumbnail: [{
                    uid     : 1,
                    name    : record.thumbnail.split('/').pop(),
                    status  : 'done',
                    url     : record.thumbnail,
                }],
                gltf: gltfFileList,
            })
        }
        this.setState({
          visible: open,
        });
    };

    // canBeSubmitted()
    // {
    //     const {name, thumbnail, gltf, type} = this.state;
    //     return type==="new" ? (name.length > 0 && thumbnail.length > 0 && gltf.length > 0) : (name.length > 0);
    // }

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const gltfFile = values.gltf.filter(element => element.name.endsWith(".gltf"));
                if(gltfFile.length !== 1) {
                    this.props.showMessage({
                        message         : 'Select one gltf File.',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'error'
                    })
                } else {
                    const {type, id} = this.state;
                    this.setState({visible: false});
                    if(type === "new") {
                        this.props.addDoorHandle({...this.state, thumbnail: values.thumbnail});
                    }
                    if(type === "edit" && id !== null) {
                        this.props.updateDoorHandle({...this.state, thumbnail: values.thumbnail}, id, this.state.guid);
                    }
                    
                }
            }
          });
    }

    handleDeleteEvent = (record) => {
        const {deleteDoorHandle} = this.props;
        confirm({
            title: 'Do you want to delete this Item?',
            onOk() {
                deleteDoorHandle(record)
            },
            onCancel() {},
        });
    }

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }
      
    render()
    {
        const { classes, doorHandle, isLoading} = this.props;
        const { thumbnail, name, gltf, visible, type} = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;
        const props = {
            name: 'file',
            multiple: true,
        };
        const uploadButton = (
            <div>
              <AddIcon />
              <div className="ant-upload-text">
                {type==="new" ? "Upload" : "Change & Upload"}
            </div>
            </div>
        );
        const columns = [
            {
                key: 'name',
                title: 'Name',
                dataIndex: 'name',
                width: 200,
            },
            {
                key: 'gltf_bin',
                title: 'Gltf & Bin file',
                dataIndex: 'gltf',
                render: (text, record) => {
                    const data = beautifyJSON(text);
                    const result = [];
                    data.forEach((element, key) => {
                        result.push(<span key={key}>{element}<br/></span>);
                    })
                    return (
                    <Tooltip placement="topLeft" title={result}>
                        <span>{text.slice(0,60) + '...'}</span>
                    </Tooltip>
                    )
                },
                width: 400,
            },
            {
                key: 'thumbnail',
                title: 'Thumbnail',
                dataIndex: 'thumbnail',
                colSpan: 2,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text}</span>
                    </Tooltip>
                )
            },
            {
                key: 'preview',
                dataIndex: 'thumbnail',
                colSpan: 0,
                width: 150,
                render: (text, record) => (
                    <Row className="list__thumbnail">
                        <img src={text} alt="" />
                    </Row>
                ),
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'id',
                width: 150,
                fixed: 'right',
                render: (text, record) => (
                    <Row>
                        <IconButton className={classes.button} aria-label="Delete" onClick={this.toggleDrawer("edit", record, true)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Edit" onClick={e => {e.preventDefault(); this.handleDeleteEvent(record);}}>
                            <DeleteIcon />
                        </IconButton>
                    </Row>
                ),
            },
        ];

        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button
                                    className="whitespace-no-wrap"
                                    variant="contained"
                                    onClick={this.toggleDrawer("new", null, true)}
                                >
                                    Add
                                </Button>
                            </FuseAnimate>
                        </div>
    
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"Door Handle"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Drawer
                                    anchor="right"
                                    open={visible}
                                    variant="temporary"
                                    onClose={this.toggleDrawer(null, null, false)}
                                    classes={{paper: classes.list}}
                                    onRendered={() => {
                                        var data = {
                                            name      : this.state.name,
                                            gltf      : this.state.gltf,
                                            thumbnail : this.state.thumbnail,
                                        }
                                        setFieldsValue({...data})}}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                            { type === "new" ? "Add" : "Edit"} Door Handle
                                        </Title>
                                        <Divider />
                                        <Form layout="vertical" style={{marginTop: "20px"}}>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="name"
                                                        {...formItemLayout}
                                                        label="Door Handle Name"
                                                    >
                                                        {getFieldDecorator("name", {
                                                            initialValue: name,
                                                            rules: [ {
                                                                required: true, message: 'Enter Door Handle.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Door Handle Name"
                                                                onChange={(e)=> {
                                                                    this.setState({name: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="gltf"
                                                        {...formItemLayout}
                                                        label="Gltf & Bin file"
                                                    >
                                                        {getFieldDecorator("gltf", {
                                                            initialValue: gltf,
                                                            rules: [{ required: true, message: 'Please upload .gltf file!' }],
                                                            valuePropName: 'fileList',
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            ( <Dragger {...props}
                                                                onChange={info => {
                                                                    const { status } = info.file;
                                                                    if (status !== 'uploading') {
                                                                        this.setState({gltf:info.fileList});
                                                                    }
                                                                    if (status === 'done') {
                                                                    message.success(`${info.file.name} file uploaded successfully.`);
                                                                    } else if (status === 'error') {
                                                                    message.error(`${info.file.name} file upload failed.`);
                                                                    }
                                                                }}
                                                                beforeUpload={fileList => {
                                                                    return false;
                                                                }}
                                                                >
                                                                <p className="ant-upload-drag-icon">
                                                                <Icon type="inbox" />
                                                                </p>
                                                                <p className="ant-upload-text">Click or drag .gltf & .bin files to this area to upload</p>
                                                                <p className="ant-upload-hint">
                                                                </p>
                                                            </Dragger>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="Thumbnail"
                                                        {...formItemLayout}
                                                        label="Thumbnail"
                                                    >
                                                        {getFieldDecorator("thumbnail", {
                                                            initialValue: thumbnail,
                                                            valuePropName: 'fileList',
                                                            rules: [{ required: true, message: 'Please upload Thumbnail!' }],
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onRemove={file => {
                                                                    this.setState({
                                                                        thumbnail: [],
                                                                    });
                                                                }}
                                                                beforeUpload={(file, fileList) => {
                                                                    this.setState({
                                                                        thumbnail: fileList,
                                                                    });
                                                                    return false;
                                                                }}
                                                                >
                                                                {thumbnail.length > 0 ? null : uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Form>  
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: 0,
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                                                Cancel
                                            </Button>
                                            <Button 
                                                onClick={this.handleSubmit} 
                                                variant="contained" 
                                                color="primary" 
                                                // disabled={!this.canBeSubmitted()}
                                            >
                                                {type === "new" ? "ADD" : "UPDATE"}
                                            </Button>
                                        </div>
                                    </div>
                                </Drawer>
                                <Table 
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={doorHandle}
                                    indentSize={20}
                                    scroll={{x: 1500, y: 500}}
                                />
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getDoorHandle: Actions.getDoorHandle,
        addDoorHandle: Actions.addDoorHandle,
        deleteDoorHandle: Actions.deleteDoorHandle,
        updateDoorHandle: Actions.updateDoorHandle,
        showMessage     : appActions.showMessage,
    }, dispatch);
}

function mapStateToProps({doorHandle, fuse})
{
    return {
        doorHandle: doorHandle.doorHandle.doorHandle,
        isLoading:fuse.loading.isLoading
    }
}
const DoorHandleComponent = Form.create()(DoorHandle);

export default withReducer('doorHandle', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(DoorHandleComponent))));