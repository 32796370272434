import axios from 'axios';
import {showMessage} from 'app/store/actions/fuse';
import { API_SERVER_URL, jsonArray, shapeSVGValue, uploadFiles, putFiles, deleteFiles, canUpload } from 'app/main/config';
import { uploadLoading }from 'app/store/actions/fuse';

export const GET_FURNISHING = '[FURNISHING] GET FURNISHING';
export function getFurnishing() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/furnishing/read/furnishing`)
        .then(response => {
            const {furnishing} = response.data;
                dispatch({
                    type:GET_FURNISHING,
                    furnishing: furnishing,
                })
        }).catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to load FURNISHING data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        });    
    })
}

export const ADD_FURNISHING = '[FURNISHING] ADD FURNISHING';
export function addFurnishing(data) {
    var data_gltf = {
        'gltf' : jsonArray(data.gltf)
    };

    var data_string = JSON.stringify(data_gltf);
 
    var newData = {
        name: data.name,
        thumbnail: data.thumbnail[0].name,
        shape_svg: shapeSVGValue(data.shape_svg),
        gltf : data_string,
        width     : data.width,
        height    : data.height,
        length    : data.length,
    }
   
    const formData = new FormData();
    formData.append("thumbnail",data.thumbnail[0].originFileObj);
    formData.append("shape_svg",data.shape_svg[0].originFileObj);
    data.gltf.forEach(fElement => {
        formData.append("gltf",fElement.originFileObj);
    })
    
    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/furnishing/insert/furnishing`, {
            ...newData
        })
        .then(res => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                uploadFiles(data.gltf.filter(file => file.originFileObj !== undefined), `uploads/Furnishing/${res.data.data.guid + '_' + res.data.data.id}/gltf/`).then(gltf => {
                    uploadFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/Furnishing/${res.data.data.guid + '_' + res.data.data.id}/thumbnail/`).then(thumbnail => {                        
                        uploadFiles(data.shape_svg.filter(file => file.originFileObj !== undefined), `uploads/Furnishing/${res.data.data.guid + '_' + res.data.data.id}/shape_svg/`).then(shape_svg => {
                        const uploadData =[ 
                            {
                                fieldName: 'gltf',
                                type: 'multi',
                                data: [
                                    {
                                        jsonFieldName: 'gltf',
                                        data: gltf
                                    }
                                ]
                            }, 
                            {
                                fieldName: 'thumbnail',
                                type: 'single',
                                data: thumbnail
                            },
                            {
                                fieldName: 'shape_svg',
                                type: 'single',
                                data: shape_svg
                            }
                        ];
                        axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                            {
                                id: res.data.data.id,
                                tableName: "furnishing",
                                data: uploadData
                            }
                        )
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                dispatch(showMessage({
                                    message         : 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: ADD_FURNISHING
                                    })
                                ]).then(() => dispatch(getFurnishing()))
                            } else {
                                console.log(success);
                            }
                        })
                    }).catch(err => {
                        console.log("failed to upload furnishing shape_svg. ", err);
                        return;
                    })
                    })
                    .catch(err => {
                        console.log("failed to upload furnishing thumbnail. ", err);
                        return;
                    })
                })
                .catch(err => {
                    console.log("failed to upload furnishing gltfs. ", err);
                    return;
                })
            } else {
                formData.append("guid" , res.data.data.guid);
                formData.append("id" , res.data.data.id);
                axios.post(`${API_SERVER_URL}/api/furnishing/upload`, formData)
                .then(response => {
                    const {success} = response.data;
                    dispatch(uploadLoading(false));
                    if(success === true) {
                        axios.post(`${API_SERVER_URL}/uploads/clear`)
                        .then(res => {
                            if(res.data.success === true ){
                                dispatch(showMessage({
                                    message         : 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: ADD_FURNISHING
                                    })
                                ]).then(() => dispatch(getFurnishing()))
                            }
                        })
                    }
                }).catch(err => {
                    dispatch(showMessage({
                        message         : 'Failed to add FURNISHING data',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'error'
                    }));
        
                    console.log("err", err);
                });    
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to upload image',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });
    })
}

export const DELETE_FURNISHING = '[FURNISHING] DELETE FURNISHING';
export function deleteFurnishing(record) {
    return ((dispatch) => {
        var p1 = new Promise((resolve, reject) => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production" ) {
                const {gltf} = JSON.parse(record.gltf);
                gltf.push(record.thumbnail);
                gltf.push(record.shape_svg);
                deleteFiles(gltf).then(res => {
                    resolve("success");
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                })
            } else {
                resolve("success");
            }
        })
        p1.then(p1Value => {
            axios.post(`${API_SERVER_URL}/api/furnishing/remove/furnishing`, {
                id: record.id
            })
            .then(response => {
                const {success} = response.data;
                dispatch(uploadLoading(false));
                if(success === true) {
                    dispatch(showMessage({
                        message         : 'Successfully Deleted',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                    Promise.all([
                        dispatch({
                            type: DELETE_FURNISHING
                        })
                    ]).then(() => dispatch(getFurnishing()))
                }
            }).catch(err => {
                dispatch(uploadLoading(false));
                dispatch(showMessage({
                    message         : 'Failed to delete FURNISHING data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));

                console.log("err", err);
            });        
        })
        .catch( err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message: 'AWS S3 DELETE FURNISHING ERROR',
                autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
            return;
        })
    })
}

export const UPDATE_FURNISHING = '[FURNISHING] UPDATE FURNISHING';
export function updateFurnishing(data, id, guid) {
    var data_gltf = {
        'gltf' : jsonArray(data.gltf)
    };
    var data_string = JSON.stringify(data_gltf);
    var updateData = {
        id        : id,
        name      : data.name,
        thumbnail : jsonArray([data.thumbnail[0]]),
        shape_svg : shapeSVGValue(data.shape_svg),
        gltf      : data_string,
        width     : data.width,
        height    : data.height,
        length    : data.length,
    }

    // const formData = new FormData();
    // formData.append("thumbnail",data.thumbnail[0].originFileObj);
    // data.gltf.forEach(fElement => {
    //     formData.append("gltf",fElement.originFileObj);
    // })

    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/furnishing/update/furnishing`, {
            ...updateData
        })
        .then(res => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                putFiles(data.gltf.filter(file => file.originFileObj !== undefined), `uploads/Furnishing/${guid + '_' + id}/gltf/`).then(gltf => {
                    putFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/Furnishing/${guid + '_' + id}/thumbnail/`).then(thumbnail => {      
                        putFiles(data.shape_svg.filter(file => file.originFileObj !== undefined), `uploads/Furnishing/${guid + '_' + id}/shape_svg/`).then(shape_svg => {                  
                        const uploadData =[ 
                            {
                                fieldName: 'gltf',
                                type: 'multi',
                                data: [
                                    {
                                        jsonFieldName: 'gltf',
                                        data: gltf
                                    }
                                ]
                            }, 
                            {
                                fieldName: 'thumbnail',
                                type: 'single',
                                data: thumbnail
                            },
                            {
                                fieldName: 'shape_svg',
                                type: 'single',
                                data: shape_svg
                            }
                        ];
                        axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                            {
                                id: id,
                                tableName: "furnishing",
                                data: uploadData
                            }
                        )
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                dispatch(showMessage({
                                    message         : 'Successfully Updated',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: UPDATE_FURNISHING
                                    })
                                ]).then(() => dispatch(getFurnishing()))
                            } else {
                                console.log(success);
                            }
                        })
                    }).catch(err => {
                        console.log("failed to put furnishing shape_svg. ", err);
                        return;
                    })
                    })
                    .catch(err => {
                        console.log("failed to put furnishing thumbnail. ", err);
                        return;
                    })
                })
                .catch(err => {
                    console.log("failed to put furnishing gltfs. ", err);
                    return;
                })
            }
            else {
                if(data.thumbnail[0].originFileObj !== undefined || data.shape_svg[0].originFileObj !== undefined || canUpload(data.gltf) === true) {
                    const formData = new FormData();
                    formData.append("guid" , guid);
                    formData.append("id" , id);
                    if(data.thumbnail[0].originFileObj !== undefined) {
                        formData.append("thumbnail", data.thumbnail[0].originFileObj);
                    }
                    if(data.shape_svg[0].originFileObj !== undefined) {
                        formData.append("shape_svg", data.shape_svg[0].originFileObj);
                    }
                    if(canUpload(data.gltf)) {
                        data.gltf.forEach(fElement => {
                            formData.append("gltf",fElement.originFileObj);
                        })
                    }
                    axios.post(`${API_SERVER_URL}/api/furnishing/upload`, formData)
                    .then(response => {
                        const {success} = response.data;
                        dispatch(uploadLoading(false));
                        if(success === true) {
                            axios.post(`${API_SERVER_URL}/uploads/clear`)
                            .then(res => {
                                if(res.data.success === true ){
                                    dispatch(showMessage({
                                        message         : 'Successfully Updated',
                                        autoHideDuration: 2000,
                                        anchorOrigin    : {
                                            vertical  : 'top',
                                            horizontal: 'right'
                                        },variant : 'success'
                                    }));
                                    Promise.all([
                                        dispatch({
                                            type: UPDATE_FURNISHING
                                        })
                                    ]).then(() => dispatch(getFurnishing()))
                                }
                            })
                        }
                    }).catch(err => {
                        dispatch(showMessage({
                            message         : 'Failed to update FURNISHING data',
                            autoHideDuration: 2000,
                            anchorOrigin    : {
                                vertical  : 'top',
                                horizontal: 'right'
                            },variant : 'error'
                        }));
            
                        console.log("err", err);
                    });
                }else{
                    dispatch(uploadLoading(false));
                    dispatch(showMessage({
                        message         : 'Successfully Updated',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                    Promise.all([
                        dispatch({
                            type: UPDATE_FURNISHING
                        })
                    ]).then(() => dispatch(getFurnishing()))
                }        
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to upload image',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });    
    })
}